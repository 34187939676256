import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

export const Hero = (props) => {
  const { description, image, title } = props

  return (
    <div className="container mt-12">
      <div className="flex relative rounded-md overflow-hidden bg-gray-300">
        <Img
          alt={title}
          className="max-h-60 object-cover grayscale-2 blend-multiply"
          fadeIn={false}
          fixed={[
            { ...image.childImageSharp.desktop, media: `(min-width: 768px)` },
            { ...image.childImageSharp.mobile, media: `(max-width: 767px)` },
          ]}
        />

        <div
          className="absolute inset-0"
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgba(68, 64, 60, 0) 24%, rgba(68, 64, 60, .5) 100%)",
          }}
        ></div>

        <div className="absolute w-full h-full flex flex-col justify-center items-center p-5 lg:p-6 text-white text-center text-shadow">
          <h1 className="text-2xl lg:text-4xl font-bold leading-tight">
            {title}
          </h1>
          <h3 className="text-lg font-medium md:w-2/3">{description}</h3>
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    childImageSharp: PropTypes.shape({
      desktop: PropTypes.object.isRequired,
      mobile: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

export const query = graphql`
  fragment HeroImageFragment on File {
    url: publicURL
    childImageSharp {
      mobile: fixed(width: 768, quality: 80) {
        ...GatsbyImageSharpFixed_withWebp
      }
      desktop: fixed(width: 1496, quality: 85) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`
